import _ from "lodash";
import { i18n, TFunction } from "next-i18next";

import { getEnumKeyByEnumValue } from "src/utils/common";
import { translate } from "src/locales";
import { testPassword, isInvalidPassword } from "src/utils/regex";

export enum ErrorCode {
  UNKOWN_ERROR = "0",
  WRONG_WALLET_NAME = "1",
  EXISTS_WALLET_NAME = "2",
  WRONG_ADDRESS = "3",
  LACK_BALANCE_AMOUNT = "4",
  FORBIDDEN_ERROR_HOUR = "5",
  NETWORK_ERROR = "6",
  WRONG_RECOVERKIT_INPUT = "7",
  ACCESS_GUEST = "8",
  GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET = "9",
  GASFEE_INSUFFICIENT_WITHDRAW_WALLET = "10",
  EMPTY_ALLOWED_ADDRESS_NAME = "11",
  EXISTS_ALLOWED_ADDRESS = "12",
  ALREADY_EXISTS_ALLOWED_ADDRESS_NAME = "13",
  REGEX_INVALID_PASSWORD = "14",
  SAME_EMAIL_INVALID_PASSWORD = "15",
  SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD = "16",
  EXPIRED_PASSWORD = "17",
  EXPIRED_TOKEN = "18",
  EXPIRED_ACTION = "19",
  UNAUTHORIZED = "4001",
  EMAIL_DOES_NOT_EXISTS = "4002",
  FORBIDDEN_RESOURCE = "4003",
  OTP_AUTHENTICATION_FAILED = "4004",
  INVALID_PASSWORD = "4005",
  DUPLICATED_WALLET_NAME = "4006",
  NOT_VERIFIED_IP = "4008",
  TIMEOUT_IP = "4009",
  ALREADY_VERIFIED_IP = "4010",
  INVALID_IP_VERIFY_REQUEST = "4011",
  NOT_COMPLETED_CUSTODY_REQUEST_EXISTS = "4012",
  NOT_IDENTICAL_SIGNER = "4013",
  ALREADY_CREATED_ADDRESS_BOOK = "4014",
  ALREADY_WHITELISTED_IP_ADDRESS = "4015",
  EXPIRED_INVITATION = "4016",
  INVALID_INVITATION = "4017",
  CANCELED_INVITATION = "4018",
  ALREADY_SIGNED_UP_ACCOUNT = "4019",
  DUPLICATED_ADDRESS_BOOK_DELETE_REQUEST = "4020",
  DUPLICATED_ADDRESS_BOOK_ADDRESS = "4021",
  DUPLICATED_ADDRESS_BOOK_NAME = "4022",
  INVALID_COLD_WALLET_SIGNING = "4023",
  DUPLICATED_EMAIL = "4024",
  ALREADY_INVITED_ACCOUNT = "4025",
  DUPLICATED_ORGANIZATION_NAME = "4026",
  ALREADY_INVITED_ACCOUNT_BY_DIFFERENT_ORGANIZATION = "4027",
  SESSION_TIME_OUT = "4028",
  PASSPHRASE_EXPIRED = "4029",
  PASSPHRASE_MAX_ATTEMPT = "4030",
  INVALID_TRANSACTION = "4032",
  ALREADY_EXISTING_COIN = "4033",
  ALREADY_AUTHENTICATED_OTP = "4034",
  ALREADY_USED_PASSPHRASE = "4035",
  ALREADY_PROCESSING_CSV = "4036",
  TRY_TO_PROCESS_CSV = "4037",
  INTERNAL_SERVER = "5000",
  INVALID_MINIMUM_BALANCE = "5001",
  INACTIVE_WALLET = "5002",
  NOT_PROCESSED_IN_WALLET = "5003",
  FAIL_TO_SEND_EMAIL = "5006",
  INVALID_QRCODE = "40000",
  INVALID_ORDER_QRCODE = "40001",
  INVALID_COMPONENT_QRCODE = "40002",
}

type WalletErrorProps = {
  status: ErrorCode;
  message: string;
  data?: Record<string, any>;
};

export class WalletError extends Error {
  public status: ErrorCode;
  public data?: Record<string, any>;

  constructor(props: WalletErrorProps) {
    super(props.message);
    this.status = props.status;
    this.data = props.data;
    const message = props.message ?? "";
    this.name =
      getEnumKeyByEnumValue(ErrorCode, props.status) ??
      `WalletError-${props.status}-${message.substr(message.length - 10)}`;
  }
}

type SDKErrorProps = {
  name: string;
  message: string;
  stack?: string;
};

export class SDKError extends Error {
  constructor(props: SDKErrorProps) {
    super(props.message);
    const { name, stack } = props;
    this.name = name;
    this.stack = stack;
  }
}

const errorCodeByRegexMessage: Record<string, ErrorCode> = {
  ".*Array contains invalid value:.*": ErrorCode.WRONG_RECOVERKIT_INPUT,
  ".*insufficient funds of the sender for value.*":
    ErrorCode.GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET,
  ".*invalid password: it is blacklisted.*":
    ErrorCode.SAME_EMAIL_INVALID_PASSWORD,
  ".*Invalid password: new password is the same as previous one.*":
    ErrorCode.SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD,
};

export const errorCodeByMessage = (message: string) => {
  for (const key of _.keys(errorCodeByRegexMessage)) {
    if (new RegExp(key, "g").test(message)) {
      return errorCodeByRegexMessage[key];
    }
  }
  return null;
};

export const errorMessageByMessage = (message: string) => {
  const errorCode = errorCodeByMessage(message);
  if (errorCode) {
    return errorMessageByCode(errorCode);
  }
  return message;
};

export const errorMessageByCode = (
  errorCode: ErrorCode,
  defaultMessage = "",
) => {
  const t = i18n?.t.bind(i18n) as TFunction;
  const errorMessages: { [key in ErrorCode]: string } = {
    // 0
    [ErrorCode.UNKOWN_ERROR]: translate(["libs", "error", "UNKOWN_ERROR"], t),
    [ErrorCode.WRONG_WALLET_NAME]: translate(
      ["libs", "error", "WRONG_WALLET_NAME"],
      t,
    ),
    [ErrorCode.EXISTS_WALLET_NAME]: translate(
      ["libs", "error", "EXISTS_WALLET_NAME"],
      t,
    ),
    [ErrorCode.WRONG_ADDRESS]: translate(["libs", "error", "WRONG_ADDRESS"], t),
    [ErrorCode.LACK_BALANCE_AMOUNT]: translate(
      ["libs", "error", "LACK_BALANCE_AMOUNT"],
      t,
    ),
    [ErrorCode.NETWORK_ERROR]: translate(["libs", "error", "NETWORK_ERROR"], t),
    [ErrorCode.WRONG_RECOVERKIT_INPUT]: translate(
      ["libs", "error", "WRONG_RECOVERKIT_INPUT"],
      t,
    ),
    [ErrorCode.ACCESS_GUEST]: translate(["libs", "error", "ACCESS_GUEST"], t),
    [ErrorCode.FORBIDDEN_ERROR_HOUR]: translate(
      ["libs", "error", "FORBIDDEN_ERROR_HOUR"],
      t,
    ),
    [ErrorCode.GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET]: translate(
      ["libs", "error", "GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET"],
      t,
    ),
    [ErrorCode.GASFEE_INSUFFICIENT_WITHDRAW_WALLET]: translate(
      ["libs", "error", "GASFEE_INSUFFICIENT_WITHDRAW_WALLET"],
      t,
    ),
    [ErrorCode.EMPTY_ALLOWED_ADDRESS_NAME]: translate(
      ["libs", "error", "EMPTY_ALLOWED_ADDRESS_NAME"],
      t,
    ),
    [ErrorCode.EXISTS_ALLOWED_ADDRESS]: translate(
      ["libs", "error", "EXISTS_ALLOWED_ADDRESS"],
      t,
    ),
    [ErrorCode.ALREADY_EXISTS_ALLOWED_ADDRESS_NAME]: translate(
      ["libs", "error", "ALREADY_EXISTS_ALLOWED_ADDRESS_NAME"],
      t,
    ),
    [ErrorCode.REGEX_INVALID_PASSWORD]: "",
    [ErrorCode.SAME_EMAIL_INVALID_PASSWORD]: translate(
      ["libs", "error", "SAME_EMAIL_INVALID_PASSWORD"],
      t,
    ),
    [ErrorCode.SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD]: translate(
      ["libs", "error", "SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD"],
      t,
    ),
    [ErrorCode.EXPIRED_PASSWORD]: translate(
      ["libs", "error", "EXPIRED_PASSWORD"],
      t,
    ),
    [ErrorCode.EXPIRED_TOKEN]: translate(["libs", "error", "EXPIRED_TOKEN"], t),
    [ErrorCode.EXPIRED_ACTION]: translate(
      ["libs", "error", "EXPIRED_TOKEN"],
      t,
    ),
    // 4000
    [ErrorCode.UNAUTHORIZED]: translate(["libs", "error", "UNAUTHORIZED"], t),
    [ErrorCode.EMAIL_DOES_NOT_EXISTS]: translate(
      ["libs", "error", "EMAIL_DOES_NOT_EXISTS"],
      t,
    ),
    [ErrorCode.FORBIDDEN_RESOURCE]: "FORBIDDEN_RESOURCE",
    [ErrorCode.OTP_AUTHENTICATION_FAILED]: translate(
      ["libs", "error", "OTP_AUTHENTICATION_FAILED"],
      t,
    ),
    [ErrorCode.INVALID_PASSWORD]: translate(
      ["libs", "error", "INVALID_PASSWORD"],
      t,
    ),
    [ErrorCode.DUPLICATED_WALLET_NAME]: "",
    [ErrorCode.NOT_VERIFIED_IP]: translate(
      ["libs", "error", "NOT_VERIFIED_IP"],
      t,
    ),
    [ErrorCode.TIMEOUT_IP]: translate(["libs", "error", "TIMEOUT_IP"], t),
    [ErrorCode.ALREADY_VERIFIED_IP]: translate(
      ["libs", "error", "ALREADY_VERIFIED_IP"],
      t,
    ),
    [ErrorCode.INVALID_IP_VERIFY_REQUEST]: translate(
      ["libs", "error", "INVALID_IP_VERIFY_REQUEST"],
      t,
    ),
    [ErrorCode.NOT_COMPLETED_CUSTODY_REQUEST_EXISTS]: translate(
      ["libs", "error", "NOT_COMPLETED_CUSTODY_REQUEST_EXISTS"],
      t,
    ),
    [ErrorCode.NOT_IDENTICAL_SIGNER]: translate(
      ["libs", "error", "NOT_IDENTICAL_SIGNER"],
      t,
    ),
    [ErrorCode.ALREADY_CREATED_ADDRESS_BOOK]: translate(
      ["libs", "error", "ALREADY_CREATED_ADDRESS_BOOK"],
      t,
    ),
    [ErrorCode.ALREADY_WHITELISTED_IP_ADDRESS]: "",
    [ErrorCode.EXPIRED_INVITATION]: translate(
      ["libs", "error", "EXPIRED_INVITATION"],
      t,
    ),
    [ErrorCode.INVALID_INVITATION]: translate(
      ["libs", "error", "INVALID_INVITATION"],
      t,
    ),
    [ErrorCode.CANCELED_INVITATION]: translate(
      ["libs", "error", "CANCELED_INVITATION"],
      t,
    ),
    [ErrorCode.ALREADY_SIGNED_UP_ACCOUNT]: translate(
      ["libs", "error", "ALREADY_SIGNED_UP_ACCOUNT"],
      t,
    ),
    [ErrorCode.DUPLICATED_ADDRESS_BOOK_DELETE_REQUEST]: translate(
      ["libs", "error", "DUPLICATED_ADDRESS_BOOK_DELETE_REQUEST"],
      t,
    ),
    [ErrorCode.DUPLICATED_ADDRESS_BOOK_ADDRESS]: translate(
      ["libs", "error", "DUPLICATED_ADDRESS_BOOK_ADDRESS"],
      t,
    ),
    [ErrorCode.DUPLICATED_ADDRESS_BOOK_NAME]: translate(
      ["libs", "error", "DUPLICATED_ADDRESS_BOOK_NAME"],
      t,
    ),
    [ErrorCode.INVALID_COLD_WALLET_SIGNING]: "",
    [ErrorCode.DUPLICATED_EMAIL]: translate(
      ["libs", "error", "DUPLICATED_EMAIL"],
      t,
    ),
    [ErrorCode.ALREADY_INVITED_ACCOUNT]: "",
    [ErrorCode.DUPLICATED_ORGANIZATION_NAME]: "",
    [ErrorCode.ALREADY_INVITED_ACCOUNT_BY_DIFFERENT_ORGANIZATION]: translate(
      ["libs", "error", "ALREADY_INVITED_ACCOUNT_BY_DIFFERENT_ORGANIZATION"],
      t,
    ),
    [ErrorCode.SESSION_TIME_OUT]: translate(
      ["libs", "error", "SESSION_TIME_OUT"],
      t,
    ),
    [ErrorCode.PASSPHRASE_EXPIRED]: "",
    [ErrorCode.PASSPHRASE_MAX_ATTEMPT]: translate(
      ["libs", "error", "PASSPHRASE_MAX_ATTEMPT"],
      t,
    ),
    [ErrorCode.INVALID_TRANSACTION]: translate(
      ["libs", "error", "INVALID_TRANSACTION"],
      t,
    ),
    [ErrorCode.ALREADY_EXISTING_COIN]: translate(
      ["libs", "error", "ALREADY_EXISTING_COIN"],
      t,
    ),
    // 5000
    [ErrorCode.INTERNAL_SERVER]: "",
    [ErrorCode.INVALID_MINIMUM_BALANCE]: translate(
      ["libs", "error", "INVALID_MINIMUM_BALANCE"],
      t,
    ),
    [ErrorCode.INACTIVE_WALLET]: "",
    [ErrorCode.NOT_PROCESSED_IN_WALLET]: "",
    [ErrorCode.FAIL_TO_SEND_EMAIL]: "",
    [ErrorCode.INVALID_QRCODE]: translate(
      ["libs", "error", "INVALID_QRCODE"],
      t,
    ),
    [ErrorCode.INVALID_ORDER_QRCODE]: translate(
      ["libs", "error", "INVALID_ORDER_QRCODE"],
      t,
    ),
    [ErrorCode.INVALID_COMPONENT_QRCODE]: translate(
      ["libs", "error", "INVALID_COMPONENT_QRCODE"],
      t,
    ),
    [ErrorCode.ALREADY_AUTHENTICATED_OTP]: translate(
      ["libs", "error", "ALREADY_AUTHENTICATED_OTP"],
      t,
    ),
    [ErrorCode.ALREADY_USED_PASSPHRASE]: translate(
      ["libs", "error", "ALREADY_USED_PASSPHRASE"],
      t,
    ),
    [ErrorCode.ALREADY_PROCESSING_CSV]: translate(
      ["libs", "error", "ALREADY_PROCESSING_CSV"],
      t,
    ),
    [ErrorCode.TRY_TO_PROCESS_CSV]: translate(
      ["libs", "error", "TRY_TO_PROCESS_CSV"],
      t,
    ),
  };

  return errorMessages[errorCode] || defaultMessage;
};

export const makeWalletErrorByCode = (
  errorCode: ErrorCode,
  defaultMessage = "",
) => {
  return new WalletError({
    status: errorCode,
    message: errorMessageByCode(errorCode, defaultMessage),
  });
};

export const makePasswordErrorMessage = (password: string) => {
  const { hasAtLeast8Char, hasAlphabet, hasNumber, hasSpecialChar } =
    testPassword(password);

  if (!isInvalidPassword(password)) {
    return "";
  }
  if (i18n?.language === "ko") {
    const rules = [
      !hasAlphabet ? "영문 대문자 또는 소문자" : "",
      !hasNumber ? "숫자 한 개 이상" : "",
      [
        !hasSpecialChar ? "특수문자를 포함한" : "",
        !hasAtLeast8Char ? "8자 이상의" : "",
      ]
        .filter((rule) => Boolean(rule))
        .join(" "),
    ];
    return `${rules
      .filter((rule) => Boolean(rule))
      .join(", ")} 비밀번호로 설정해주세요.`;
  }

  const rules = [
    !hasAlphabet ? "an uppercase or lowercase letter" : "",
    !hasNumber ? "at least one number" : "",
    !hasSpecialChar ? "a special character" : "",
    !hasAtLeast8Char ? "at least 8 characters long" : "",
  ].filter((rule) => Boolean(rule));

  if (rules.length === 0) {
    return "";
  }

  const formattedRules =
    rules.length > 1
      ? `${rules.slice(0, -1).join(", ")} and ${rules[rules.length - 1]}`
      : rules[0];

  return `Please set your password to include ${formattedRules}.`;
};

export const makePasswordError = (password: string) => {
  return makeWalletErrorByCode(
    ErrorCode.REGEX_INVALID_PASSWORD,
    makePasswordErrorMessage(password),
  );
};

export const makeAlreadyAuthenticateOtpErrorMessage = (
  untilNextCodeTimeInSecond?: number,
) => {
  return i18n?.language === "ko"
    ? `입력하신 OTP 코드는 만료되었습니다. ${untilNextCodeTimeInSecond ?? "??"}초 후에 재생성된 코드를 입력해주세요.`
    : `The OTP code you entered has expired. Please enter the newly generated code after ${untilNextCodeTimeInSecond ?? "??"} seconds.`;
};

export const errorMessageByInvalidPasswordErrorCode = (
  errorCode: ErrorCode,
  message: string,
) => {
  if (errorCode !== ErrorCode.INVALID_PASSWORD) {
    return;
  }
  const isSameMessage = errorMessageByMessage(message) === message;
  return isSameMessage
    ? errorMessageByCode(errorCode, message)
    : errorMessageByMessage(message);
};

export const invalidInvitedErrorCodes: ErrorCode[] = [
  ErrorCode.INVALID_INVITATION,
  ErrorCode.CANCELED_INVITATION,
  ErrorCode.ALREADY_SIGNED_UP_ACCOUNT,
  ErrorCode.EXPIRED_INVITATION,
];
