import React, { useState } from "react";
import QRCode from "qrcode.react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import MaterialButton from "src/components/Button/MaterialButton";
import BottomButton from "src/components/Button/BottomButton";
import images from "src/images";
import {
  H3Bold,
  Body1RegularPre,
  Body1BoldSpan,
  H4Bold,
  Body2RegularPre,
} from "src/components/Typography/Typography";
import { translate } from "src/locales";

import MaterialTextField from "src/components/TextField/MaterialTextField";
import ToggleButton from "src/components/Button/ToggleButton";
import MaterialAlert from "src/components/Alert/MaterialAlert";

type Props = {
  email: string;
  otpKey: string;
  otpUrl: string;
  onConfirm: () => void;
} & WithTranslation;

type ViewType = "qrCode" | "input";

type States = {
  viewType: ViewType;
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H3Bold)``;

const HighlightDescription = styled(Body1BoldSpan)`
  color: ${palette.primary.main};
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const ContentDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${palette.mediumgrey.sub};
  margin-bottom: 48px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OTPIconView = styled.div`
  display: flex;
  padding: 9px;
  box-sizing: border-box;
  border: 1px solid ${palette.mediumgrey.main};
  border-radius: 12px;
`;

const QRCodeGroup = styled.div`
  display: flex;
  margin-top: ${spacing.medium3};
  padding: 24px 184px;
  background-color: ${palette.lightgrey.main};
`;

const GoogleAuthenticator = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.small6};
`;

const GoogleAuthenticatorTitle = styled(H4Bold)`
  color: ${palette.darkgrey.main};
`;

const DownloadButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.small3};
`;

const DownloadDivider = styled.div`
  width: 1px;
  height: 32px;
  background-color: ${palette.mediumgrey.main};
  margin-left: 12px;
  margin-right: 12px;
`;

const DownloadButton = styled(MaterialButton)``;

const ViewTypeButtonGroup = styled.div`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.lightgrey.main};
  padding: 20px;
  margin-top: 48px;
`;

const KeyTextField = styled(MaterialTextField)`
  margin-top: 16px;
`;

const InputGuideDescription = styled(Body2RegularPre)`
  color: ${palette.darkgrey.sub};
  margin-top: 24px;
`;

const OTPGuideForm = (props: Props) => {
  const { email, otpKey, otpUrl, onConfirm, t } = props;
  const [state, setState] = useState<States>({
    viewType: "qrCode",
  });
  const { viewType } = state;
  const OtpIcon = images.otpIcon;

  const onSelected = (viewType: ViewType) => {
    setState({
      viewType,
    });
  };

  return (
    <MaterialForm
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>{translate(["otpGuideForm", "title"], t)}</Title>
          <Description>
            {translate(["otpGuideForm", "description1"], t)}
            <HighlightDescription>
              {translate(["otpGuideForm", "description2"], t)}
            </HighlightDescription>
            {translate(["otpGuideForm", "description3"], t)}
          </Description>
        </Header>
      }
      ContentComponent={
        <Content>
          <ContentDivider />
          <ContentHeader>
            <OTPIconView>
              <OtpIcon />
            </OTPIconView>
            <GoogleAuthenticator>
              <GoogleAuthenticatorTitle>
                Google Authenticator
              </GoogleAuthenticatorTitle>
              <DownloadButtonGroup>
                <DownloadButton
                  startIcon={
                    <MaterialIcon
                      fontSize={20}
                      name="android_custom"
                      color={palette.white.main}
                    />
                  }
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
                    );
                  }}
                >
                  {translate(["otpGuideForm", "androidDownloadButton"], t)}
                </DownloadButton>
                <DownloadDivider />
                <DownloadButton
                  startIcon={
                    <MaterialIcon
                      fontSize={20}
                      name="ios"
                      color={palette.white.main}
                    />
                  }
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/kr/app/google-authenticator/id388497605",
                    );
                  }}
                >
                  {translate(["otpGuideForm", "iosDownloadButton"], t)}
                </DownloadButton>
              </DownloadButtonGroup>
            </GoogleAuthenticator>
          </ContentHeader>
          {viewType === "qrCode" ? (
            <QRCodeGroup>
              <QRCode value={otpUrl} size={96} />
            </QRCodeGroup>
          ) : (
            <InputGroup>
              <MaterialTextField
                disabled={true}
                label={translate(["otpGuideForm", "account"], t)}
                type="text"
                size={"large"}
                value={email}
              />
              <KeyTextField
                disabled={true}
                label={translate(["otpGuideForm", "key"], t)}
                type="text"
                size={"large"}
                value={otpKey}
              />
              <InputGuideDescription>
                {translate(["otpGuideForm", "inputGuideDescription"], t)}
              </InputGuideDescription>
            </InputGroup>
          )}
          <ViewTypeButtonGroup>
            <ToggleButton<ViewType>
              size={"medium"}
              data={[
                {
                  label: translate(["otpGuideForm", "qrcode"], t),
                  value: "qrCode",
                },
                {
                  label: translate(["otpGuideForm", "input"], t),
                  value: "input",
                },
              ]}
              onSelected={onSelected}
              selected={viewType}
            />
          </ViewTypeButtonGroup>
        </Content>
      }
      FooterComponent={
        <BottomButton
          buttonName={translate(["otpGuideForm", "nextButton"], t)}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: onConfirm,
            isLoading: false,
          }}
        />
      }
    />
  );
};

export default withTranslation()(OTPGuideForm);
