import React from "react";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import { inject, observer } from "mobx-react";
import { useTranslation } from "next-i18next";

import { palette } from "src/themes/palette";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import StepByStepLayout from "src/components/Layout/StepByStepLayout";
import MaterialButton from "src/components/Button/MaterialButton";
import { Props as StepIndicatorItemProps } from "src/components/Indicator/StepIndicatorItem";
import { H6BoldSpan } from "src/components/Typography/Typography";
import SetAccountEmailForm from "src/components/Form/Account/SetAccountEmailForm";
import OTPForm from "src/components/Form/OTPForm";
import { IAuthStore } from "src/stores/AuthStore";
import { IStore } from "src/stores/Store";
import { IWalletStore } from "src/stores/WalletStore";
import RequestResetPasswordEmailForm from "src/components/Form/Account/RequestResetPasswordEmailForm";
import ErrorSnackbar from "src/components/Snackbar/ErrorSnackbar";
import { ErrorCode, errorMessageByCode } from "src/libs/error";
import { translate } from "src/locales";

type Params = {
  open: boolean;
  onClose: () => void;
};

type Inject = {
  authStore?: IAuthStore;
  walletStore?: IWalletStore;
};

type Props = Partial<Inject> & Params;

const AppbarLeftCaption = styled(H6BoldSpan)`
  color: ${palette.darkgrey.main};
`;

const AppbarRightCloseButton = styled(MaterialButton)`
  border-radius: 26px;
  padding: 10px;
  margin-right: 10px;
`;

const CloseButton = styled(MaterialButton)`
  color: ${palette.bluegrey.dark};
  margin-left: -24px;
`;

const RequestResetPasswordModal = inject(
  ({ store }: { store: IStore }): Inject => ({
    authStore: store.authStore,
    walletStore: store.walletStore,
  }),
)(
  observer((props: Props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [stepIndex, setStepIndex] = React.useState(0);
    const [email, setEmail] = React.useState("");

    const onClose = () => {
      setStepIndex(0);
      setEmail("");
      props.onClose();
    };

    const nextStep = () => {
      setStepIndex((prevState) => prevState + 1);
    };

    const prevStep = () => {
      setStepIndex((prevState) => prevState - 1);
    };

    const onEmail = async (email: string) => {
      const isExists = await props.authStore?.verifyEmail?.(email);

      if (!isExists) {
        throw new Error(errorMessageByCode(ErrorCode.EMAIL_DOES_NOT_EXISTS));
      }
      setEmail(email);
      nextStep();
    };

    const otpSubmit = async (otpCode: string) => {
      try {
        await props.authStore?.recoverPassphrase?.({ email, otpCode });
        nextStep();
      } catch (error: any) {
        if (error.status === ErrorCode.OTP_AUTHENTICATION_FAILED) {
          throw error;
        }
        enqueueSnackbar(<ErrorSnackbar message={error.message} />);
      }
    };

    const steps: Array<StepIndicatorItemProps & { children: JSX.Element }> =
      (() => {
        return [
          {
            stepNumber: "1",
            name: translate(["setAccountEmailForm", "title"], t),
            children: <SetAccountEmailForm onSubmit={onEmail} />,
          },
          {
            stepNumber: "2",
            name: translate(["otpForm", "title"], t),
            children: (
              <OTPForm
                submitName={translate(["next"], t)}
                onSubmit={otpSubmit}
                ActionBottomLeftComponent={
                  <CloseButton
                    variant="text"
                    color="primary"
                    size={"large"}
                    onClick={prevStep}
                  >
                    {translate(["previousStep"], t)}
                  </CloseButton>
                }
              />
            ),
          },
          {
            stepNumber: "3",
            name: translate(["requestResetPasswordModal", "step3"], t),
            children: (
              <RequestResetPasswordEmailForm email={email} onSubmit={onClose} />
            ),
          },
        ].map((item, index) => {
          return {
            ...item,
            status:
              stepIndex === index
                ? "active"
                : index < stepIndex
                  ? "visited"
                  : "normal",
          };
        });
      })();

    return (
      <StepByStepLayout
        open={props.open}
        onClose={onClose}
        appBarLeftComponent={
          <AppbarLeftCaption>
            {translate(["requestResetPasswordModal", "title"], t)}
          </AppbarLeftCaption>
        }
        appBarRightComponent={
          <AppbarRightCloseButton
            variant="link_touch"
            color="primary"
            size={"large"}
            onClick={onClose}
          >
            <MaterialIcon
              name={"clear"}
              fontSize={26}
              color={palette.bluegrey.dark}
            />
          </AppbarRightCloseButton>
        }
        indicatorData={steps}
      >
        {steps[stepIndex].children}
      </StepByStepLayout>
    );
  }),
);

export default RequestResetPasswordModal;
