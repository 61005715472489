import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { requests } from "src/apis/requests";
import {
  makeDetailedClientRequestDtoToRequestItem,
  IntegrationRequestStatus,
  transformClientRequestTypeToIntegrationRequestType,
  IntegrationRequestType,
  getIntergationProceedingRequestStatusItemByStatus,
} from "src/interfaces/request";
import {
  ClientSubmittedRequestDto,
  ClientRequestType,
  TransferStatus,
} from "src/__generate__/api";
import { getRootStore } from "src/stores/StoreHelper";
import { i18n } from "next-i18next";

type Variables = {
  walletId: string;
  integrationStatus?: IntegrationRequestStatus;
  type?: ClientRequestType;
};

const DEFAULT_PAGE_SIZE = 15;
const INITIALIZE_VARIABLES: Variables = {
  integrationStatus: undefined,
  walletId: "",
};

const Proceeding = types
  .model("Proceeding", {
    data: types.optional(
      types.array(types.frozen<ClientSubmittedRequestDto>()),
      [],
    ),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    variables: types.frozen<Variables>(INITIALIZE_VARIABLES),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get requestViews() {
        return _.orderBy(
          self.data.map((item) =>
            makeDetailedClientRequestDtoToRequestItem(
              item,
              getRootStore(self).walletStore,
            ),
          ),
          ["createdAt"],
          ["desc"],
        );
      },
      get integrationType() {
        return self.variables.type
          ? transformClientRequestTypeToIntegrationRequestType(
              self.variables.type,
            )
          : IntegrationRequestType.ALL;
      },
      get isSearch() {
        const { walletId, integrationStatus, type } = self.variables;
        return Boolean(walletId || integrationStatus || type);
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.hasMore = true;
      self.variables = INITIALIZE_VARIABLES;
      self.data.clear();
    };

    const fetch = flow(function* () {
      const { walletId, integrationStatus, type } = self.variables;
      const proceedingRequestStatusItem =
        getIntergationProceedingRequestStatusItemByStatus(
          integrationStatus ?? IntegrationRequestStatus.ALL,
          i18n?.t,
        );

      const response: RetrieveAsyncFunc<typeof requests> = yield requests({
        request: {
          walletId: walletId ? walletId : undefined,
          type,
          custodyRequestStatuses:
            proceedingRequestStatusItem?.custodyStatus ?? [],
          transferStatuses:
            (proceedingRequestStatusItem?.transferStatus as TransferStatus[]) ??
            [],
          walletStatuses: proceedingRequestStatusItem?.walletStatus ?? [],
        },
        pageable: {
          page: self.page,
          size: self.size,
        },
      });
      self.data.replace(response.results);
      self.totalCount = response.pagination.totalCount;
      self.hasMore = false;
    });

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
      yield fetch();
    });

    const initialize = flow(function* (options: Partial<Variables>) {
      clear();
      self.variables = {
        ...options,
        walletId: options.walletId ?? "",
      };
      yield fetch();
    });

    const append = flow(function* () {
      yield setPage({
        page: self.page + 1,
      });
    });

    const search = flow(function* (options: Partial<Variables>) {
      const existVariables = self.variables;
      clear();
      self.variables = {
        ...existVariables,
        ...options,
      };
      yield fetch();
    });

    return {
      clear,
      initialize,
      setPage,
      search,
      append,
      refresh: fetch,
    };
  });

export type IProceeding = Instance<typeof Proceeding>;
export default Proceeding;
