import { flow, Instance, types } from "mobx-state-tree";
import { t } from "i18next";

import { organizationMe } from "src/apis/organizations";
import { getRootStore } from "src/stores/StoreHelper";
import { storage as localStorage } from "src/libs/localStorage";
import Organization from "src/stores/model/Organization";
import OrganizationCoins from "src/stores/OrganizationCoins";
import { translate } from "src/locales";

const OrganizationStore = types
  .model("OrganizationStore", {
    organizations: types.optional(types.map(Organization), {}),
    coins: types.optional(OrganizationCoins, {}),
    selectedOrganizationId: types.optional(types.string, ""),
  })
  .views((self) => {
    return {
      get isFirstOrganization() {
        const user = getRootStore(self).authStore.user;
        return user?.isSuperAdmin && !localStorage().isOTPInitialized();
      },
      get selectedOrganization() {
        const selected = self.organizations.get(self.selectedOrganizationId);
        if (!selected) {
          throw new Error(translate(["stores", "organization", "error"], t));
        }
        return selected;
      },
      get selectedOrganizationMembers() {
        return this.selectedOrganization.organizationMembers;
      },
    };
  })
  .actions((self) => {
    const initialize = flow(function* (orgId: string) {
      self.organizations.put(
        Organization.create({
          id: orgId,
          name: "",
        }),
      );
      self.selectedOrganizationId = orgId;
      const user = getRootStore(self).authStore.user;

      yield Promise.all([
        me(),
        user.isKodaAdminOrAdmin
          ? self.selectedOrganization.organizationMembers.initialize()
          : Promise.resolve(),
        self.coins.initialize(),
      ]);
      self.selectedOrganization.organizationMembers.updateOrganizationMember(
        user,
      );
    });

    const me = flow(function* () {
      const response: RetrieveAsyncFunc<typeof organizationMe> =
        yield organizationMe();
      const { name } = response;
      self.selectedOrganization.initialize(name);
    });

    const showFirstOrganizationDialog = () => {
      localStorage().setOTPInitialized(true);
      self.selectedOrganization.orgInitialized();
    };

    return {
      initialize,
      showFirstOrganizationDialog,
    };
  });

export type IOrganizationStore = Instance<typeof OrganizationStore>;
export default OrganizationStore;
