import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import BottomButton from "src/components/Button/BottomButton";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import {
  H3Bold,
  Body1RegularPre,
  body1BoldCSS,
} from "src/components/Typography/Typography";
import { translate } from "src/locales";

export type Props = {
  className?: string;
  email: string;
  onSubmit: () => void;
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 11px;
`;

const Title = styled(H3Bold)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};

  & span {
    ${body1BoldCSS}
    color: ${palette.primary.main};
  }
`;

const RequestResetPasswordEmailForm = (props: Props) => {
  const { className, email, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>
            <CheckIcon
              name="check_circle"
              fontSize={30}
              color={palette.green.main}
            />
            {translate(["requestResetPasswordEmailForm", "title"], t)}
          </Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: t("requestResetPasswordEmailForm.description", {
                email,
              }),
            }}
          />
        </Header>
      }
      FooterComponent={
        <BottomButton
          buttonName={translate(["confirm"], t)}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: onSubmit,
            isLoading: false,
          }}
        />
      }
    />
  );
};

export default RequestResetPasswordEmailForm;
