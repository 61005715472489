import _ from "lodash";
import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dynamic from "next/dynamic";
import styled from "@emotion/styled";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import MaterialTextField from "src/components/TextField/MaterialTextField";
import MaterialButton from "src/components/Button/MaterialButton";
import BottomButton from "src/components/Button/BottomButton";
import Snackbar from "src/components/Snackbar/Snackbar";
import { IStore } from "src/stores/Store";
import { IAuthStore, MAX_ATTEPMT_PASSWORD } from "src/stores/AuthStore";
import MaterialModal from "src/components/Dialog/MaterialModal";
import {
  ErrorCode,
  errorMessageByCode,
  invalidInvitedErrorCodes,
  makeWalletErrorByCode,
} from "src/libs/error";
import { getAccountOtpGuidePath } from "src/pages/account/otp/guide";
import { getAccountOtpRegisterPath } from "src/pages/account/otp/register";
import { Params as ErrorParams } from "src/hocs/withErrorSnackbar";
import { Params as MessageParams } from "src/hocs/withMessageSnackbar";
import { H3Bold } from "src/components/Typography/Typography";
import { translate } from "src/locales";
import { i18n, useTranslation } from "next-i18next";
import InvalidInvitedMemberDialog from "src/components/Dialog/InvalidInvitedMemberDialog";
import { isKodaAdmin } from "src/libs/env";
import { AuthorityName, ResourceType, RoleDto } from "src/__generate__/api";
import RequestResetPasswordModal from "src/organizations/Account/RequestResetPassword";
import { format } from "src/utils/string";
import { useRouter } from "next/router";
import useLoading from "src/hooks/useLoading";

type Params = ErrorParams &
  MessageParams & {
    redirect?: string;
  };

type Inject = {
  authStore: IAuthStore;
};

type Props = Inject;

type FormStates = {
  email: string;
  password: string;
};

type FormErrorStates = { [key in keyof FormStates]: string };

const Title = styled(H3Bold)``;

const Content = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ForgottenPasswordButton = styled(MaterialButton)`
  color: ${palette.bluegrey.dark};
  margin-left: -24px;
`;

const AccountLayout = dynamic(
  () => import("src/components/Layout/AccountLayout"),
  { ssr: false },
);

const existsCustodyAuthorityNames = (roles: RoleDto[]) => {
  return (
    roles
      .filter((item) => item.resource.type === ResourceType.Organization)
      .filter((item) =>
        [
          AuthorityName.CustodyAdmin,
          AuthorityName.CustodyManager,
          AuthorityName.CustodySigner,
          AuthorityName.CustodyViewer,
          AuthorityName.CustodyAccountEmailViewer,
          AuthorityName.CustodyOperator,
        ].some((authorityName) => authorityName === item.authority.name),
      ).length > 0
  );
};

const AccountLogin = inject(
  ({ store }: { store: IStore }): Inject => ({
    authStore: store.authStore,
  }),
)(
  observer((props: Props) => {
    const router = useRouter();
    const params = (router.query ?? {}) as Params;

    const { t } = useTranslation("common");
    const { enqueueSnackbar } = useSnackbar();
    const [dialog, setDialog] = React.useState<React.ReactNode | null>(null);
    const [modal, setModal] = React.useState<"requestResetPassword" | null>(
      null,
    );
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [errors, setErrors] = React.useState<FormErrorStates>({
      email: "",
      password: "",
    });

    const { isLoading: isSubmitLoading, wrapperLoading: submit } = useLoading({
      func: async () => {
        const { firstLogin } = props.authStore;
        const { redirect } = params;
        try {
          const response = await firstLogin({ email, password });
          if (isKodaAdmin && !existsCustodyAuthorityNames(response.roles)) {
            throw makeWalletErrorByCode(ErrorCode.EMAIL_DOES_NOT_EXISTS);
          }
          if (!isKodaAdmin && existsCustodyAuthorityNames(response.roles)) {
            throw makeWalletErrorByCode(ErrorCode.EMAIL_DOES_NOT_EXISTS);
          }
          const initialized = !Boolean(response.otp);
          if (initialized) {
            router.push(
              getAccountOtpRegisterPath({
                redirect: redirect as string,
              }),
            );
            return;
          }
          router.push(
            getAccountOtpGuidePath({
              redirect: redirect as string,
            }),
          );
        } catch (error: any) {
          const errorStatus = error.status;
          if (ErrorCode.EMAIL_DOES_NOT_EXISTS === errorStatus) {
            const errorMessage = errorMessageByCode(errorStatus);
            setErrors({
              email:
                ErrorCode.EMAIL_DOES_NOT_EXISTS === errorStatus
                  ? errorMessage
                  : "",
              password: "",
            });
            return;
          }
          if (
            [ErrorCode.INVALID_PASSWORD, ErrorCode.PASSPHRASE_MAX_ATTEMPT].some(
              (status) => status === errorStatus,
            )
          ) {
            const errorPasswordAttempt = Number(
              _.get(error.errorData, ["attempt"], 0),
            );
            const errorPasswordMessage =
              errorStatus === ErrorCode.PASSPHRASE_MAX_ATTEMPT ||
              errorPasswordAttempt === MAX_ATTEPMT_PASSWORD
                ? errorMessageByCode(ErrorCode.PASSPHRASE_MAX_ATTEMPT)
                : format(
                    i18n?.language === "ko"
                      ? "비밀번호를 %attempt%회 잘못 입력하셨습니다. 비밀번호를 5회 이상 잘못 입력할 경우 로그인이 차단됩니다."
                      : "You have entered the wrong password %attempt% times. If you enter the wrong password more than 5 times, your login will be blocked.",
                    {
                      attempt: String(errorPasswordAttempt),
                    },
                  );
            setErrors({
              email: "",
              password: errorPasswordMessage,
            });
            return;
          }
          enqueueSnackbar(<Snackbar message={error.message} />);
        }
      },
    });

    const close = () => {
      setDialog(null);
      setModal(null);
    };

    const showInvalidInvitedMemberDialog = () => {
      const { err } = params;
      if (
        !err ||
        !invalidInvitedErrorCodes.some((errorCode) => errorCode === err)
      ) {
        return;
      }
      setDialog(
        <InvalidInvitedMemberDialog
          description={errorMessageByCode(err)}
          onConfirm={close}
        />,
      );
    };

    const showForgottenPasswordDialog = () => {
      setModal("requestResetPassword");
    };

    const onChangeText = (
      name: keyof FormStates,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = event.target.value;
      if (name === "email") {
        setEmail(value);
      } else {
        setPassword(value);
      }
    };

    React.useEffect(() => {
      const { logout } = props.authStore;

      // _app에서 store.initialize에서 에러 발생 시 로그인 페이지로 redirect하는 로직과 충돌이 일어나 무한 새로고침이 발생해서 이 부분 개선 전까지 주석처리
      // if (serverAccessToken) {
      //   window.location.replace("/");
      //   return;
      // }
      showInvalidInvitedMemberDialog();
      if (params.err === ErrorCode.SESSION_TIME_OUT) {
        logout();
      }
    }, []);

    return (
      <AccountLayout>
        <React.Fragment>
          <MaterialForm
            size={"large"}
            HeaderComponent={
              <Title>
                {translate(["pages", "account", "login", "title"], t)}
              </Title>
            }
            ContentComponent={
              <Content>
                <MaterialTextField
                  label={translate(
                    ["pages", "account", "login", "emailLabel"],
                    t,
                  )}
                  autoFocus={true}
                  placeholder={"example@email.com"}
                  onChange={_.partial(onChangeText, "email")}
                  value={email}
                  style={{
                    marginBottom: spacing.medium2,
                  }}
                  onEnter={submit}
                  helperText={errors.email}
                />
                <MaterialTextField
                  label={translate(
                    ["pages", "account", "login", "passwordLabel"],
                    t,
                  )}
                  placeholder={""}
                  onChange={_.partial(onChangeText, "password")}
                  value={password}
                  type={"password"}
                  onEnter={submit}
                  helperText={errors.password}
                />
              </Content>
            }
            FooterComponent={
              <BottomButton
                LeftComponent={
                  <ForgottenPasswordButton
                    variant="text"
                    color="primary"
                    size={"large"}
                    onClick={showForgottenPasswordDialog}
                  >
                    {translate(
                      ["pages", "account", "login", "forgottenPassword"],
                      t,
                    )}
                  </ForgottenPasswordButton>
                }
                buttonName={translate(
                  ["pages", "account", "login", "loginButton"],
                  t,
                )}
                buttonProps={{
                  variant: "contained",
                  color: "primary",
                  size: "large",
                  onClick: submit,
                  disabled: !(email.length > 0 && password.length > 0),
                  isLoading: isSubmitLoading ?? false,
                }}
              />
            }
          />
          <MaterialModal open={Boolean(dialog)} onClose={close}>
            {dialog}
          </MaterialModal>
          <RequestResetPasswordModal
            open={modal === "requestResetPassword"}
            onClose={close}
          />
        </React.Fragment>
      </AccountLayout>
    );
  }),
);

export default AccountLogin;
