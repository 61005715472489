import { Instance, types } from "mobx-state-tree";
import { isKodaAdmin } from "src/libs/env";

import LoginIps from "src/stores/LoginIps";
import {
  RoleDto,
  AuthorityName,
  ResourceType,
  AccountStatus,
  OtpDto,
  Language,
} from "src/__generate__/api";

export const GUEST_ID = "GUEST";

const PASSPHRASE_EXPIRED_SEVEN_DAYS_BEFORE = 7;

const User = types
  .model("User", {
    id: types.identifier,
    email: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    orgId: types.optional(types.string, ""),
    status: types.frozen<AccountStatus>(AccountStatus.Active),
    password: types.optional(types.string, ""),
    lang: types.frozen<Language | null>(null),
    roles: types.frozen<RoleDto[]>([]),
    loginIps: types.optional(
      types.late(() => LoginIps),
      {},
    ),
    createdAt: types.optional(types.string, ""),
    updatedAt: types.optional(types.string, ""),
    otp: types.frozen<OtpDto | null>(null),
    passphraseLastModifiedDayBefore: types.frozen<number | null>(null),
    isPassphraseExpired: types.frozen<boolean | null>(null),
    passphraseExpiredPeriodDays: types.frozen<number | null>(null),
  })
  .views((self) => {
    return {
      get roleViews() {
        return self.roles.map((role) => role.authority.name);
      },
      get role() {
        if (this.roleViews.includes(AuthorityName.OrganizationSuperAdmin)) {
          return AuthorityName.OrganizationSuperAdmin;
        }
        return AuthorityName.OrganizationUser;
      },
      roleViewsByResource(resouceType: ResourceType) {
        return self.roles.filter((item) => item.resource.type === resouceType);
      },
      get isPasswordExiredBefore() {
        const diffExpiredDays =
          (self.passphraseExpiredPeriodDays ?? 0) -
          (self.passphraseLastModifiedDayBefore ?? 0);
        return (
          diffExpiredDays < PASSPHRASE_EXPIRED_SEVEN_DAYS_BEFORE &&
          !self.isPassphraseExpired
        );
      },
      get orgRolesByResourceExcludedMember() {
        return (
          self.roles
            .filter((item) => item.resource.type === ResourceType.Organization)
            .filter(
              (item) => item.authority.name !== AuthorityName.OrganizationUser,
            ) ?? []
        );
      },
    };
  })
  .views((self) => {
    return {
      get walletRoleWalletIds() {
        const walletRoles = self.roleViewsByResource(ResourceType.Wallet);
        const walletOwnerWalletIds = walletRoles
          .filter((role) => role.authority.name === AuthorityName.WalletOwner)
          .map((role) => role.resource.id);
        const walletManagerWalletIds = walletRoles
          .filter((role) => role.authority.name === AuthorityName.WalletManager)
          .map((role) => role.resource.id);
        const walletMemberWalletIds = walletRoles
          .filter((role) => role.authority.name === AuthorityName.WalletMember)
          .map((role) => role.resource.id);
        const walletViewerWalletIds = walletRoles
          .filter((role) => role.authority.name === AuthorityName.WalletViewer)
          .map((role) => role.resource.id);
        return {
          [AuthorityName.WalletOwner]: walletOwnerWalletIds,
          [AuthorityName.WalletManager]: walletManagerWalletIds,
          [AuthorityName.WalletMember]: walletMemberWalletIds,
          [AuthorityName.WalletViewer]: walletViewerWalletIds,
        };
      },
      get authorityItem() {
        const walletOwnerWalletIds =
          this.walletRoleWalletIds[AuthorityName.WalletOwner];
        const walletManagerWalletIds =
          this.walletRoleWalletIds[AuthorityName.WalletManager];
        const walletMemberWalletIds =
          this.walletRoleWalletIds[AuthorityName.WalletMember];
        const walletViewerWalletIds =
          this.walletRoleWalletIds[AuthorityName.WalletViewer];
        return {
          id: self.id,
          name: self.name,
          email: self.email,
          status: self.status,
          orgRoles: self.orgRolesByResourceExcludedMember.map(
            (item) => item.authority,
          ),
          walletOwnerWalletIds,
          walletManagerWalletIds,
          walletMemberWalletIds,
          walletViewerWalletIds,
        };
      },
      get custodyAuthorityItem() {
        return {
          id: self.id,
          name: self.name,
          email: self.email,
          roles: self
            .roleViewsByResource(ResourceType.Organization)
            .map((item) => item.authority.name),
          loginIps: self.loginIps.loginIpViews.map(
            (loginIp) => loginIp.address,
          ),
          me: false,
        };
      },
      get authorityNameByWalletId() {
        const byWalletId: Record<string, AuthorityName> = {};
        const roles = self.roleViewsByResource(ResourceType.Wallet);
        for (const item of roles) {
          const key = item.resource.id;
          byWalletId[key] = item.authority.name;
        }
        return byWalletId;
      },
    };
  })
  .views((self) => {
    return {
      isWalletOwnerAndManager(walletId: string) {
        return [AuthorityName.WalletOwner, AuthorityName.WalletManager].some(
          (authorityName) =>
            authorityName === self.authorityNameByWalletId[walletId],
        );
      },
      isWalletOwner(walletId: string) {
        return [AuthorityName.WalletOwner].some(
          (authorityName) =>
            authorityName === self.authorityNameByWalletId[walletId],
        );
      },
      isWalletManager(walletId: string) {
        return [AuthorityName.WalletManager].some(
          (authorityName) =>
            authorityName === self.authorityNameByWalletId[walletId],
        );
      },
      isWalletMember(walletId: string) {
        return [AuthorityName.WalletMember].some(
          (authorityName) =>
            authorityName === self.authorityNameByWalletId[walletId],
        );
      },
      isWalletViewer(walletId: string) {
        return [AuthorityName.WalletViewer].some(
          (authorityName) =>
            authorityName === self.authorityNameByWalletId[walletId],
        );
      },
      get isUserAdminAndSuperAdmin() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [
              AuthorityName.OrganizationSuperAdmin,
              AuthorityName.OrganizationUsersAdmin,
            ].some((authorityName) => authorityName === item.authority.name),
          );
      },
      get isUserAdmin() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.OrganizationUsersAdmin].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isSuperAdmin() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.OrganizationSuperAdmin].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get custodyAuthorityNames() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .filter((item) =>
            [
              AuthorityName.CustodyAdmin,
              AuthorityName.CustodyManager,
              AuthorityName.CustodySigner,
              AuthorityName.CustodyOperator,
              AuthorityName.CustodyViewer,
            ].some((authorityName) => authorityName === item.authority.name),
          );
      },
      get isCustodyAdmin() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.CustodyAdmin].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isCustodyManager() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.CustodyManager].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isCustodySigner() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.CustodySigner].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isCustodyOperator() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.CustodyOperator].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isCustodyViewer() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.CustodyViewer].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isWalletAdmin() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.OrganizationWalletsAdmin].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      get isMember() {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) =>
            [AuthorityName.OrganizationUser].some(
              (authorityName) => authorityName === item.authority.name,
            ),
          );
      },
      hasRoleByAuthorityName(authorityName: AuthorityName) {
        return self
          .roleViewsByResource(ResourceType.Organization)
          .some((item) => authorityName === item.authority.name);
      },
      getRolesByWalletId(walletId: string) {
        return self.authorityNameByWalletId[walletId];
      },
    };
  })
  .views((self) => {
    return {
      get isSuperAndWalletAdmin() {
        return Boolean(self.isSuperAdmin || self.isWalletAdmin);
      },
      get isAdmin() {
        return Boolean(
          self.isSuperAdmin || self.isWalletAdmin || self.isUserAdmin,
        );
      },
      get isKodaAdminOrAdmin() {
        return this.isAdmin || isKodaAdmin;
      },
    };
  })
  .actions((self) => {
    const setUserName = (name: string) => {
      self.name = name;
    };
    const setLanguage = (language: Language) => {
      self.lang = language;
    };

    return {
      setUserName,
      setLanguage,
    };
  });

export type IUser = Instance<typeof User>;
export default User;
