import hoistNonReactStatic from "hoist-non-react-statics";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import Snackbar from "src/components/Snackbar/Snackbar";
import {
  ErrorCode,
  errorMessageByCode,
  invalidInvitedErrorCodes,
} from "src/libs/error";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import { palette } from "src/themes/palette";
import MaterialButton from "src/components/Button/MaterialButton";
import ErrorSnackbar from "src/components/Snackbar/ErrorSnackbar";
import { translate } from "src/locales";

export type Params = { err?: ErrorCode };

const withErrorSnackbar = <P extends Record<string, any>>(
  TargetComponent: React.ComponentType<P>,
): any => {
  const WithErrorSnackbar = (props: P) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const params: Params = router.query ?? ({} as Params);

    const navigateToEmail = () => {
      window.open("mailto:support@kodax.com");
    };

    useEffect(() => {
      const errorCode = params.err;
      if (!(errorCode && errorMessageByCode(errorCode))) {
        return;
      }
      if (invalidInvitedErrorCodes.some((code) => code === errorCode)) {
        return;
      }
      if (
        [ErrorCode.INVALID_IP_VERIFY_REQUEST].some((code) => code === errorCode)
      ) {
        enqueueSnackbar(
          <Snackbar
            IconComponent={
              <MaterialIcon
                name="cancel"
                fontSize={22}
                color={palette.red.main}
              />
            }
            message={errorMessageByCode(errorCode)}
            action={
              <MaterialButton
                variant="link_highlight"
                color="lightgrey"
                size={"large"}
                onClick={navigateToEmail}
              >
                {translate(["contactSupport"], t)}
              </MaterialButton>
            }
          />,
        );
        return;
      }
      if ([ErrorCode.TIMEOUT_IP].some((code) => code === errorCode)) {
        enqueueSnackbar(
          <Snackbar
            IconComponent={
              <MaterialIcon
                name="warning"
                fontSize={22}
                color={palette.red.main}
              />
            }
            message={errorMessageByCode(errorCode)}
          />,
        );
        return;
      }

      setTimeout(
        () =>
          enqueueSnackbar(
            <ErrorSnackbar message={errorMessageByCode(errorCode)} />,
          ),
        100,
      );
    }, []);

    return <TargetComponent {...(props as P)} />;
  };

  hoistNonReactStatic(WithErrorSnackbar, TargetComponent);
  return WithErrorSnackbar;
};

export default withErrorSnackbar;
